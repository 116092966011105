import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Box from "reusecore/src/elements/Box";
import Tooltip from "reusecore/src/elements/Tooltip";
import {ic_sentiment_satisfied} from 'react-icons-kit/md/ic_sentiment_satisfied';
import {ic_info_outline} from 'react-icons-kit/md/ic_info_outline'
import Icon from 'react-icons-kit';
import { themeGet } from 'styled-system';

const ListCardWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    justify-items: center;
    margin: 0 5px;
    padding-bottom: 20px;
    .box {
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        box-shadow: ${themeGet('defaultBoxShadow')};
        padding: 10px 0;
        min-width: 70%;
        max-width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        
        .left {
            flex-grow: 2;
            color: rgb(137,138,146);
            font-size: 10px;
            font-weight: bold;
            text-transform: capitalize;

            .info {
                margin-left: 5px;
            }
        }

        .right {
            flex-grow: 1;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconContainer {
                .positive {
                    color: ${themeGet('colors.primary')};
                }

                .negative {
                    color: ${themeGet('colors.secondary')};
                }    
            }
        }
        .text {
            font-weight: 600;
            font-size: 18px;
            margin: 0;
            color: black;
        }
        .eurSymbol {
            font-size: 18px;
            font-weight: normal;
            margin-right: 5px;
        }
        p {
            margin: 0;
        }

        .tooltipContainer {
            text-transform: none;
        }
    }
    div:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
    div:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
    div:nth-child(3) { grid-area: 1 / 3 / 2 / 4; }
    div:nth-child(4) { grid-area: 2 / 1 / 3 / 2; }
    div:nth-child(5) { grid-area: 2 / 2 / 3 / 3; }
    div:nth-child(6) { grid-area: 2 / 3 / 3 / 4; }

    ${props => props.childrenCount.length > 0 && props.childrenCount.length <= 3 && css`
        grid-template-rows: 1fr;
    `}

    ${props => props.childrenCount.length === 0 && css`
        display: none;
    `}

    @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 15px;
        .box {
            min-width: auto;
        }
        div:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
        div:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
        div:nth-child(3) { grid-area: 2 / 1 / 3 / 3; justify-self: center; }
        div:nth-child(4) { grid-area: 3 / 1 / 4 / 2; }
        div:nth-child(5) { grid-area: 3 / 2 / 4 / 3; }
        div:nth-child(6) { grid-area: 4 / 1 / 5 / 3; justify-self: center; }

        ${props => (props.childrenCount.length > 0 && props.childrenCount.length <= 2) && css`
            grid-template-rows: 1fr;
        `}

        ${props => (props.childrenCount.length > 2 && props.childrenCount.length <= 5) && css`
            grid-template-rows: 3fr;
        `}
    }
`;

export function Card({symbol = '$', label, amount, title, text, hideIcon=false}) {
    function renderIcon(amount) {
        return (
            amount > 0 && !hideIcon ? 
                <Icon size="32" className="positive" icon={ic_sentiment_satisfied}/> :
                null
        )
    }

    function isValid(amount) {
        return typeof amount === 'number' || typeof amount === 'string';
    }

    return (
        <Box className="box">
            <div className="left">
                <div>
                    {label}
                    {title && (
                        <Tooltip bubbleSize={{minWidth: 300}} message={title} className="tooltipContainer" position="top">
                            <Icon size={14} className="info" icon={ic_info_outline} />
                        </Tooltip>
                    )}
                </div>
                <p>
                    {isValid(amount) && (<Fragment><span className="eurSymbol">{symbol}</span> <span className="text">{amount > 0 ? '+' : amount < 0 ? '-' : null}{Math.abs(amount).toLocaleString(undefined, symbol === 'BTC' ? {
                        minimumFractionDigits: 8,
                        maximumFractionDigits: 8
                    } : {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</span></Fragment>)}
                    {text && <span className="text">{text}</span>}
                </p>
            </div>
            <div className="right">
                <div className="iconContainer">
                    {renderIcon(amount)}
                </div>
            </div>
        </Box>
    )
}

export default function ListCard({children = null, ...others}) {
    const childrenCount = React.Children.toArray(children);
    return (
        <ListCardWrapper childrenCount={childrenCount} {...others}>
            {children}
        </ListCardWrapper>
    );
}
