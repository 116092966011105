import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';

import { DrawerContext } from '../../contexts/DrawerContext';
import { Link } from 'gatsby';

const Menu = ({ className, menuItems, drawerClose, currentClassName }) => {
  const { dispatch } = useContext(DrawerContext);

  // Add all classs to an array
  const addAllClasses = [currentClassName];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Close drawer when click on menu item
  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <ul className={addAllClasses.join(' ')}>
      {menuItems.map((menu, index) => (
        <li key={`menu-item-${index}`}>
          <>
            {drawerClose ? (
              <Link to={menu.path} onClick={toggleDrawer}>
                {menu.label}
              </Link>
            ) : (
              <Link to={menu.path}>{menu.label}</Link>
            )}
          </>
        </li>
      ))}
    </ul>
  );
};

Menu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,
};

Menu.defaultProps = {
  currentClassName: 'is-current',
};

export default Menu;
