import React, { useContext, useState } from 'react';
import { AuthenticationContext } from 'common/src/contexts/AuthenticationContext';
import Modal, {
  ModalButtonContainer,
  ModalContent,
  ModalActionContainer,
} from 'common/src/components/Modal';
import Button from 'reusecore/src/elements/Button';
import Icon from 'react-icons-kit';
import { ic_file_download } from 'react-icons-kit/md/ic_file_download';
import styled from 'styled-components';
import { ApiContext } from 'common/src/contexts/ApiContext';

const ApproveTCCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

const ApproveTCCheckboxTitle = styled.p`
  padding-right: 10px;
`;

const TC_URL = process.env.GATSBY_TC_URL;

export default function EnsureTCAreApproved({ children, hideContent }) {
  const { validTC, setValidTC } = useContext(AuthenticationContext);
  const api = useContext(ApiContext);
  const [value, setValue] = useState(false);

  function onChange(event) {
    setValue(event.target.checked);
  }

  async function submitTC() {
    await api.updateTermsAndConditions.execute();
    setValidTC(true);
  }

  async function openTC() {
    if (typeof window !== 'undefined') {
      window.open(TC_URL);
    }
  }

  function renderModal() {
    return (
      <Modal title={'Terms And Conditions & Privacy'}>
        <ModalContent>
          You have to accept terms and condition & privacy before enabling the
          service.
          <br />
          Please consider downloading them by clicking the following button.
          <br />
        </ModalContent>
        <ModalButtonContainer>
          <Button
            isMaterial={true}
            title="Download"
            onClick={openTC}
            icon={<Icon icon={ic_file_download} />}
          />
        </ModalButtonContainer>
        <ModalActionContainer>
          <ApproveTCCheckbox>
            <ApproveTCCheckboxTitle>
              I have read and approve the terms and conditions along with
              privacy policy:
            </ApproveTCCheckboxTitle>
            <input type="checkbox" value={value} onChange={onChange} />
          </ApproveTCCheckbox>
          <Button
            isMaterial={true}
            disabled={value ? false : true}
            onClick={submitTC}
            title="I agree"
          />
        </ModalActionContainer>
      </Modal>
    );
  }

  if (validTC) return children;

  if (hideContent) return renderModal();

  return (
    <>
      {renderModal()}
      {children}
    </>
  );
}
