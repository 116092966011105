import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 94px 6px rgba(107, 83, 254, 0.1);
  padding: 40px;
  max-width: 70%;
  max-height: 70%;

  @media only screen and (max-width : 767px) {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100vw;
    transform: none;
    border-radius: 0;
    max-height: none;
    max-width: none;
    z-index: 9999;
  }
`;

const ModalTitle = styled.div`
  font-size: 40px;
  font-weight: 700;
  text-align: center;
`;

export const ModalContent = styled.div`
  padding: 20px;
  text-align: center;

`;

export const ModalButtonContainer = styled.div`
  text-align: center;
`;

export const ModalActionContainer = styled.div`
  text-align: center;
`;

function Modal(props) {
  const modalRoot = props.modalRoot || document.body;

  function renderModal() {
    return (
      <ModalContainer>
        {props.title && <ModalTitle>{props.title}</ModalTitle>}
        {props.children}
      </ModalContainer>
    );
  }

  return createPortal(renderModal(), modalRoot);
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default Modal;
