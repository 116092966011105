import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'reusecore/src/elements/Button';

import { FormActionWrapper } from './formaction.style';

const FormAction = ({ button, title, apiToCall, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);

  async function saveInfo() {
    setIsLoading(true);
    await apiToCall();
    setIsLoading(false);
  }

  return (
    <FormActionWrapper>
      <Button
        {...button}
        title={title}
        isLoading={isLoading}
        onClick={saveInfo}
        isMaterial={true}
        disabled={disabled}
      />
    </FormActionWrapper>
  );
};

FormAction.propTypes = {
  button: PropTypes.object,
  title: PropTypes.object,
};

FormAction.defaultProps = {
  button: {
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`,
  },
};

export default FormAction;
