import styled from 'styled-components';
import BannerBG from 'common/src/assets/image/crypto/main_bg.svg';

const BannerEmptyWrapper = styled.section`
  position: absolute;
  top: 0px;
  height: 500px;
  width: 100%;
  z-index: auto;
  padding-top: 210px;
  padding-bottom: 400px;
  background-image: url(${BannerBG});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1440px) {
    padding-bottom: 305px;
  }
  @media (max-width: 990px) {
    padding-top: 150px;
    padding-bottom: 210px;
  }
  @media (max-width: 768px) {
    background-image: none;
    background-color: #6d4efe;
  }

  @media only screen and (max-width: 480px) {
    padding-top: 130px;
    padding-bottom: 110px;
  }
  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 990px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }
`;
export const BgImageWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: -2px -1px;
    @media (max-width: 480px) {
      margin: -2px -1px;
    }
  }
`;
export default BannerEmptyWrapper;
