import styled from 'styled-components';

const ServiceActivationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    float: left;
    margin-right: 30px;
    margin-top: 1rem !important;
  }

  .switch_service {
    float: left;
    margin-right: 30px;
  }

  button {
  }
`;

export default ServiceActivationWrapper;
