import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.table`
  width: 100%;
  padding: 0 20px;
`;

export const Table = ({ records }) => {
  return (
    <TableWrapper>
      {records.map(record => (
        <tr>
          <td>{record.title}</td>
          <td align={record.alignValue || 'right'}>{record.value}</td>
        </tr>
      ))}
    </TableWrapper>
  );
};
