import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Button from 'reusecore/src/elements/Button';

export default function NavButton({
  className,
  to,
  title,
  onClick,
  button,
  inDrawer,
}) {
  const classes = [inDrawer ? 'navbar_drawer_button' : 'navbar_button'];

  if (className) {
    classes.push(className);
  }

  return (
    <Link className={classes.join(' ')} to={to}>
      <Button {...button} onClick={onClick} title={title} />
    </Link>
  );
}

NavButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  button: PropTypes.object,
  inDrawer: PropTypes.bool,
};

NavButton.defaultProps = {
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
  },
};
