import styled from 'styled-components';

const UserApiInfoWrapper = styled.div`
  input {
    margin-bottom: 40px;
  }

  button {
  }
`;

export default UserApiInfoWrapper;
