import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import colors from 'common/src/theme/crypto/colors';

import ServiceActivationWrapper from './serviceactivation.style';
import Switch from 'reusecore/src/elements/Switch';

const ServiceActivation = ({ apiInfo, setApiInfo }) => {
  function setServiceActivation(enabled) {
    setApiInfo({ ...apiInfo, enabled });
  }

  return (
    <ServiceActivationWrapper>
      <Text content={'Bot Disabled'} />
      <Switch
        isChecked={apiInfo.enabled}
        onChange={setServiceActivation}
        switchColor={colors.primary}
        isMaterial={true}
        className="switch_service"
      />
      <Text content={'Bot Enabled'} />
    </ServiceActivationWrapper>
  );
};

ServiceActivation.propTypes = {
  apiInfo: PropTypes.object,
  setApiInfo: PropTypes.func,
};

export default ServiceActivation;
