import React from 'react';
import Image from 'reusecore/src/elements/Image';
import Particles from '../../Agency/Particle';
import BannerBG from 'common/src/assets/image/crypto/white_bg1.svg';
import BannerWrapper from './bannerempty.style';

const BannerEmpty = () => {

    return (
        <BannerWrapper id="banner_section">
            <Particles />
        </BannerWrapper>
    );
};

export default BannerEmpty;
