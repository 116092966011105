import PropTypes from 'prop-types';
import React from 'react';
import FooterWrapper from './footer.style';

const TC_URL = process.env.GATSBY_TC_URL;
const PRIVACYPOLICY_URL = process.env.GATSBY_PRIVACYPOLICY_URL

const Footer = ({ row, col, colOne, colTwo, titleStyle }) => {
  function downloadTC() {
    if (typeof window !== 'undefined') {
      window.open(TC_URL);
    }
  }

  function downloadPrivacyPolicy() {
    if (typeof window !== 'undefined') {
      window.open(PRIVACYPOLICY_URL);
    }
  }

  return (
    <FooterWrapper id="footerSection">
      <div className="copyRight">
        <p className="copyRightText">Copyright 2022 @Orange IT Services SA</p>
      </div>
      <div className="tcPrivacyContainer">
        <a onClick={downloadTC} className="tcPrivacyText">
          Terms and Conditions
        </a>
        <a onClick={downloadPrivacyPolicy} className="tcPrivacyText">
          Privacy Policy
        </a>
      </div>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '33%', '33%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '67%', '67%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 3, 1 / 3, 1 / 3],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px'],
  },
  // widget title default style
  titleStyle: {
    color: '#FFFFFF',
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    fontWeight: '600',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    fontFamily: 'Poppins',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
};

export default Footer;
