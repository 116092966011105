import styled from 'styled-components';
import { themeGet } from 'styled-system';
import colors from 'common/src/theme/crypto/colors';

const SectionSettingsWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 40px;
  z-index: 1;
  position: relative;
  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .containerClass {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: ${themeGet('defaultBoxShadow')};
    padding: 60px 0;
  }

  .billingInformationContainer {
    margin-top: 50px;
  }

  .historyContainer {
    margin-top: 50px;
  }

  .dashboardContainer {
    margin-bottom: 20px;
    padding: 0;
  }

  .alertContainer {
    margin-bottom: 10px;
    .alertReview {
      background-color: ${themeGet('colors.secondary')};
      color: ${colors.white};
    }
  }

  .tcPrivacyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .linkToFaq {
    p {
      padding-bottom: 5px;
      font-size: 17px;
      text-decoration: underline;
      text-align: center;
    }
  }

  .tcPrivacyText {
    font-size: 16px;
    font-family: 'Lato';
    font-weight: 400;
    cursor: pointer;
  }
`;
export { SectionSettingsWrapper };
