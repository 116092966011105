import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/src/theme/crypto';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../containers/Crypto/Navbar';
import SEO from '../components/seo';
import { withAuthenticator } from 'aws-amplify-react';
import { AuthenticationProvider } from 'common/src/contexts/AuthenticationContext';
import BannerEmpty from '../containers/Crypto/BannerEmpty';
import Footer from '../containers/Crypto/Footer';
import Settings from '../containers/Crypto/Settings';
import ApiProvider from 'common/src/contexts/ApiContext';
import EnsureTCAreApproved from '../hoc/EnsureTCAreApproved';
import colors from 'common/src/theme/crypto/colors';

const AuthenticatedComponent = withAuthenticator(
  () => {
    return (
      <EnsureTCAreApproved hideContent={true}>
        <Settings />
      </EnsureTCAreApproved>
    );
  },
  {
    signUpConfig: {
      hiddenDefaults: ['phone_number'],
      signUpFields: [
        {
          label:
            'I acknowledge that I have read, and do hereby accept the terms and conditions.',
          key: 'registration_tc_field',
          required: false,
          displayOrder: 2,
          type: 'string',
        },
        {
          label:
            'We accept only UK and EU client at the moment',
          key: 'registration_disclaimer_field',
          required: false,
          displayOrder: 3,
          type: 'string',
        },
      ],
    },
    usernameAttributes: 'email',
    theme: {
      button: {
        backgroundColor: colors.primary,
      },
      a: {
        color: colors.primary,
      },
      container: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '10',
      },
    },
  }
);

export default props => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <ApiProvider>
        <AuthenticationProvider>
          <Fragment>
            <SEO title="Profile | ArbCircle | Automated Bitcoin trading platform" />
            <ResetCSS />
            <GlobalStyle />
            <ContentWrapper>
              <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                <DrawerProvider>
                  <Navbar path="/profile" />
                </DrawerProvider>
              </Sticky>
              <BannerEmpty />
              <AuthenticatedComponent />
              <Footer />
            </ContentWrapper>
          </Fragment>
        </AuthenticationProvider>
      </ApiProvider>
    </ThemeProvider>
  );
};
