import React, { useEffect, Fragment, useState } from 'react';
import ListCard, { Card } from '../../../components/RecapCard';
import withApi from '../../../hoc/WithApi';


function Recap({api}) {

    const [accountBalanceDone, setAccountBalanceDone] = useState(false);
    const [signalDone, setSignalDone] = useState(false);
    const [profitsDone, setProfitsDone] = useState(false);

    useEffect(() => {
        async function getAccountBalance() {
            try {
                api.data.accountConfiguration.exchange === 'sfox' ? await api.account.getBalanceSFOX() : await api.account.getBalance();
                setAccountBalanceDone(true);
            } catch (error) {
                setAccountBalanceDone(true);
            }
        }
    
        getAccountBalance();
    }, [api.data.accountConfiguration.exchange]);

    useEffect(() => {
        async function getSignal() {
            api.data.accountConfiguration.exchange === 'sfox' ? await api.getLatestSignalSFOX.execute() : await api.getLatestSignal.execute();
            setSignalDone(true);
        }
    
        getSignal();
    }, [api.data.accountConfiguration.exchange]);

    useEffect(() => {
        async function getProfits() {
            api.data.accountConfiguration.exchange === 'sfox' ? await api.billing.getProfitsSFOX() : await api.billing.getProfits();
            setProfitsDone(true);
        }

        getProfits()
    }, []);

    function renderAccountBalance() {
        if (!api.data.accountBalance) {
            return null;
          }
        
        const [btcBalance, usdBalance] = api.data.accountBalance
            ? [api.data.accountBalance.balance.BTC, api.data.accountBalance.balance.USD]
            : [0, 0];
        return [
            <Card symbol="BTC" label="Balance BTC" amount={btcBalance} hideIcon={true} />,
            <Card label="Balance USD" amount={usdBalance} hideIcon={true} />
        ];
    }

    function renderIndicator() {
        const recoverSignalLabel = signal => {
            return signal === 'BUY' ? 'Long Bitcoin' : 'Long US Dollar';
        };
        
        const { signal } = api.data;
    
        if (!signal) {
            return null;
        }
    
        return (
            <Card label="Current Indicator" text={recoverSignalLabel(signal)} />
        );
    }

    function getProfitsProperties() {
        if (!api.data.profitsSFOX && !api.data.profits) {
            return {};
        }
        return {
            monthlyGross: api.data.accountConfiguration.exchange === 'sfox' ? api.data.profitsSFOX.monthlyGross : api.data.profits.monthlyGross,
            netPnlUSD: api.data.accountConfiguration.exchange === 'sfox' ? api.data.profitsSFOX.netPnlUSD : api.data.profits.netPnlUSD,
            netPnlBTC:api.data.accountConfiguration.exchange === 'sfox' ? api.data.profitsSFOX.netPnlBTC : api.data.profits.netPnlBTC,
        }
    }

    function renderProfits() {
        const {monthlyGross, netPnlUSD, netPnlBTC} = getProfitsProperties();
        return monthlyGross && netPnlBTC && netPnlUSD ? [
            <Card label="Gross Pnl this month" amount={monthlyGross} title="Profit and Loss of the current month, it doesn't include any potential fee for the current month" />,
            <Card label="Net Pnl Since Inception" amount={netPnlUSD} title="Profit and Loss since the software licence was started, it includes licence fee so far requested" />,
            <Card symbol="BTC" label="Net Pnl Since Inception in BTC" amount={netPnlBTC} title="Profit and Loss since the software licence was started, it includes licence fee so far requested, converted in Bitcoin" />
        ] : null;
    }

    if (!accountBalanceDone || !signalDone || !profitsDone) {
        return null;
    }

    return (
        <ListCard>
            {renderAccountBalance()}
            {renderIndicator()}
            {renderProfits()}
        </ListCard>
    )
}

export default withApi(Recap);