import styled from 'styled-components';

export const BillingInfoWrapper = styled.div`
  .billing-form-input {
    margin-bottom: 40px;
  }
`;

export const UserTypeSwitchWrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  .user-type-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    i {
      margin: 0 10px;
    }
    p {
      margin: 0;
    }
  }
`;
