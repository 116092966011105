import React from 'react';
import PropTypes from 'prop-types';
import Input from 'reusecore/src/elements/Input';

import UserApiInfoWrapper from './userapi.style';

const UserApiInfo = ({ apiInfo, setApiInfo }) => {
  function setUid(uid) {
    setApiInfo({
      ...apiInfo,
      bitstamp: { ...apiInfo.bitstamp, uid: uid ? uid : undefined },
    });
  }

  function setKey(key) {
    setApiInfo({
      ...apiInfo,
      bitstamp: { ...apiInfo.bitstamp, key: key ? key : undefined },
    });
  }

  function setSecret(secret) {
    setApiInfo({
      ...apiInfo,
      bitstamp: { ...apiInfo.bitstamp, secret: secret ? secret : undefined },
    });
  }

  function setSfoxKey(key) {
    setApiInfo({
      ...apiInfo,
      sfox: {key}
    })
  }

  return (
    <UserApiInfoWrapper>
    {apiInfo.exchange === 'bitstamp' && (
      <React.Fragment>
        <Input
          label="Bitstamp ID"
          inputType="text"
          isMaterial={true}
          className="bs_id_input"
          aria-label="id"
          value={apiInfo.bitstamp.uid}
          onChange={setUid}
        />
        <Input
          label="Bitstamp API Key"
          inputType="text"
          isMaterial={true}
          className="bs_key_input"
          aria-label="key"
          value={apiInfo.bitstamp.key}
          onChange={setKey}
        />
        <Input
          label="Bitstamp API Secret"
          inputType="text"
          isMaterial={true}
          className="bs_secret_input"
          aria-label="secret"
          value={apiInfo.bitstamp.secret}
          onChange={setSecret}
        />
      </React.Fragment>
    )}

    {apiInfo.exchange === 'sfox' && (
      <Input
        label="SFOX API Key"
        inputType="text"
        isMaterial={true}
        className="bs_key_input"
        aria-label="key"
        value={apiInfo.sfox.key}
        onChange={setSfoxKey}
      />
    )}
      
    </UserApiInfoWrapper>
  );
};

UserApiInfo.propTypes = {
  apiInfo: PropTypes.object,
  setApiInfo: PropTypes.func,
};

export default UserApiInfo;
