import withApi from '../../../hoc/WithApi';
import UserApiInfo from '../UserApiInfo';
import ServiceActivation from '../../ServiceActivation';
import FormAction from '../FormAction';
import React, { useEffect } from 'react';
import Box from 'reusecore/src/elements/Box';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import PropTypes from 'prop-types';
import Heading from 'reusecore/src/elements/Heading';
import { SectionSettingsWrapper } from './settings.style';
import Alert from 'reusecore/src/elements/Alert';
import { Link } from 'gatsby';
import Text from 'reusecore/src/elements/Text';
import { BillingInfo } from '../BillingInfo';
import { BackTest } from '../BackTest';
import Recap from '../Recap';
import History from '../History';

const Settings = ({
  api,
  sectionWrapper,
  secTitleWrapper,
  row,
  title,
  billingTitle,
  historyTitle,
}) => {
  useEffect(() => {
    async function retrieveApiInfo() {
      await api.get.execute();
    }

    retrieveApiInfo();
  }, []);

  function downloadTC() {
    if (typeof window !== 'undefined') {
      window.open(TC_URL);
    }
  }

  function setActiveExchange(exchange) {
    setApiInfo({
      ...apiInfo,
      exchange
    })
  }

  const apiInfo = api.data.accountConfiguration;
  const setApiInfo = api.set.setAccountConfiguration;

  const TC_URL = process.env.GATSBY_TC_URL;
  const billingInformation = api.data.billingInformation;
  const setBillingInformation = api.set.setBillingInformation;
  const {
    invalidBillingFields,
    removeInvalidBillingField,
  } = api.validation.billing;
  const { updateBilling } = api;

  function setActiveExchange(exchange) {
    setApiInfo({
      ...apiInfo,
      exchange
    });
  }

  function onChange(exchange) {
    return () => setActiveExchange(exchange);
  }

  const isBitstampActive = apiInfo.exchange === 'bitstamp';
  console.log(isBitstampActive);
  return (
    <>
      <SectionSettingsWrapper>
        {apiInfo.suspended && (
          <Container className="alertContainer">
            <Alert isMaterial={true} className="alertReview">
              Account is currently being reviewed, only accounts with complete
              Billing information will pass the review.
            </Alert>
          </Container>
        )}
        <Container className="dashboardContainer">
          <Recap />
          <BackTest exchange={apiInfo.exchange} />
        </Container>
        <Box {...sectionWrapper}>
          <Container className="containerClass">
            <Box {...secTitleWrapper}>
              <FeatureBlock title={<Heading {...title} />} />
            </Box>
            <Box {...row}>
              <Box style={{ width: '70%' }}>

                <div
                  style={{display: 'flex', justifyContent: 'space-around', paddingBottom: 20}}
                >
                  Active Exchange:
                  <label for="bitstamp"><input id="bitstamp" name="exchange" type="radio" onChange={onChange('bitstamp')} value="bitstamp" checked={apiInfo.exchange === 'bitstamp'} /> Bitstamp</label>
                  <label for="sfox"><input id="sfox" name="exchange" type="radio" value="sfox" onChange={onChange('sfox')} checked={apiInfo.exchange === 'sfox'} defaultChecked={true} /> SFOX</label>
                </div>

                {!isBitstampActive && <a target="_blank" href="https://trade.sfox.com/signup?utm_source=arbcircle&utm_medium=email&utm_campaign=arbcircle" className="linkToFaq">
                  <Text
                    content={
                      'Use this link to register to SFOX'
                    }
                  />
                </a>}

                <Link to="/faq" className="linkToFaq">
                  <Text
                    content={
                      'If you need help to configure an exchange please read out Frequently Asked Questions or ask for support'
                    }
                  />
                </Link>

                <UserApiInfo setApiInfo={setApiInfo} apiInfo={apiInfo} />
                <ServiceActivation setApiInfo={setApiInfo} apiInfo={apiInfo} />
                <div className="tcPrivacyContainer">
                  <a onClick={downloadTC} className="tcPrivacyText">
                    Read our Terms and Conditions
                  </a>
                </div>
                <FormAction title={'SAVE'} apiToCall={api.update.execute} />
              </Box>
            </Box>
          </Container>
        </Box>
        <Box {...sectionWrapper}>
          <Container className="containerClass billingInformationContainer">
            <Box {...secTitleWrapper}>
              <FeatureBlock title={<Heading {...billingTitle} />} />
            </Box>
            <Box {...row}>
              <Box style={{ width: '70%' }}>
                <BillingInfo
                  billingInformation={billingInformation}
                  setBillingInformation={setBillingInformation}
                  updateBilling={updateBilling}
                  invalidFields={invalidBillingFields}
                  removeInvalidField={removeInvalidBillingField}
                />
              </Box>
            </Box>
          </Container>
        </Box>
        <Box {...sectionWrapper}>
          <Container className="containerClass historyContainer">
            <Box {...secTitleWrapper}>
              <FeatureBlock title={<Heading {...historyTitle} />} />
            </Box>
            <Box {...row}>
              <Box style={{ width: '70%' }}>
                  <History />
              </Box>
            </Box>
          </Container>
        </Box>
      </SectionSettingsWrapper>
    </>
  );
};

Settings.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  colornote: PropTypes.object,
};

Settings.defaultProps = {
  sectionWrapper: {
    id: 'settings',
    as: 'section',
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`,
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    content: 'Settings',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },
  billingTitle: {
    content: 'Billing Information',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },
  historyTitle: {
    content: 'History',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },
};

export default withApi(Settings);
