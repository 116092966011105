import React, { createContext, useState, useEffect, useContext } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { ApiContext } from './ApiContext';

Auth.configure({
  authenticationFlowType: 'USER_SRP_AUTH',
  identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
  // oauth: {
  //   domain: process.env.GATSBY_DOMAIN,
  //   redirectSignIn: process.env.REDIRECT_SIGN_IN_URL,
  //   redirectSignOut: process.env.REDIRECT_SIGN_IN_URL,
  //   scope: ['email', 'openid', 'profile'],
  //   responseType: 'code',
  // },
  region: process.env.GATSBY_REGION,
  userPoolId: process.env.GATSBY_USERPOOL_ID,
  userPoolWebClientId: process.env.GATSBY_USERPOOL_CLIENT_ID,
});

export const AuthenticationContext = createContext();

export function AuthenticationProvider({ children }) {
  const api = useContext(ApiContext);
  const [user, setUser] = useState();
  const [validTC, setValidTC] = useState(true);

  async function ensureValidTc(user) {
    if (user) {
      const ensureValidTC = await api.ensureTCApproved.execute();
      setValidTC(ensureValidTC);
    }
  }

  useEffect(() => {
    async function recoverUserInfo() {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
      ensureValidTc(user);
    }

    recoverUserInfo();
  }, []);

  const logout = async () => {
    await Auth.signOut();
    setUser(undefined);
  };

  Hub.listen('auth', data => {
    switch (data.payload.event) {
      case 'signIn':
        if (data.payload && data.payload.data) {
          setUser(data.payload.data);
          ensureValidTc(data.payload.data);
        }
        break;
      case 'signOut':
        setUser();
        break;
    }
  });

  return (
    <AuthenticationContext.Provider
      value={{ user, logout, validTC, setValidTC }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
