import styled, { css } from "styled-components";
import { themeGet } from 'styled-system';

const HistoryWrapper = styled.div`
    width: 100%;
    > div:nth-child(2) {
        max-height: 400px;
        overflow-y: auto;
    }

    
`;

const HistoryItemWrapper = styled.div`
    margin: 5px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-items: center;
    align-items: center;
    border: 1px solid;
    border-color: transparent;
    border-radius: 5px;
    .dateContainer { grid-area: 1 / 1 / 2 / 2; }
    .monthlyProfitContainer { grid-area: 1 / 2 / 2 / 3; }
    .feeContainer { grid-area: 1 / 3 / 2 / 4; }
    .iconContainer { grid-area: 1 / 4 / 2 / 5; }
    ${props => props.selected && css`
    border-color: ${themeGet('colors.primary', '#028489')}
    .iconContainer i {
        color: ${themeGet('colors.primary', '#028489')};
    }
  `}
`;

const HistoryButtonContainer = styled.div`
    display: flex;
    > div + div {
        margin-left: 15px;
    }
`;

export {
    HistoryWrapper,
    HistoryItemWrapper,
    HistoryButtonContainer
}