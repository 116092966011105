import React, { useEffect, useState } from "react";
import Icon from "react-icons-kit";
import {ic_check} from 'react-icons-kit/md/ic_check'
import Button from "reusecore/src/elements/Button";
import ListGrid from "reusecore/src/elements/ListGrid";
import withApi from "../../../hoc/WithApi";
import FormAction from "../FormAction";
import { HistoryButtonContainer, HistoryItemWrapper, HistoryWrapper } from "./history.style";
import { Parser } from 'json2csv';


const headers = [
    'date',
    'allTimeProfitAndLoss',
    'incrementalDeposits',
    'incrementalWithdrawals',
    'convertedBalance',
    'netAUM',
    'dailyProfitUSD',
    'monthlyCumulativeProfit',
    'monthlyCumulativeProfitAndLoss',
    'monthlyNetProfitAndLoss',
    'negativeProfitAndLoss',
    'monthDailyCalculatedFee',
    'paidFee'
]

function convertToCSV(headers, objArray) {
    const parser = new Parser({fields: headers});
    return parser.parse(objArray);
}

function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    var csv = convertToCSV(headers, items);

    var exportedFilenmae = fileTitle ? fileTitle + '.csv' : 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

const csvHeaders = {
    'date': 'Date',
    'allTimeProfitAndLoss': 'Profit since inception',
    'incrementalDeposits': 'Total deposits',
    'incrementalWithdrawals': 'Total Withdrawals',
    'convertedBalance': 'Balance in $',
    'netAUM': 'Asset Under Management',
    'dailyProfitUSD': 'Daily Profit in USD',
    'monthlyCumulativeProfit': 'Monthly Profit in USD',
    'monthlyCumulativeProfitAndLoss': 'Monthly Profit with negative profit from previous month',
    'monthlyNetProfitAndLoss': 'Monthly Profit with fee included',
    'negativeProfitAndLoss': 'Negative Profit',
    'monthDailyCalculatedFee': 'Fee calculated daily'
}

function BillingHistoryItem({isSelected, billing, onSelect}) {
    const date = new Date(billing.date);
    const onClick = () => {
        onSelect(billing);
    }
    return (
        <HistoryItemWrapper selected={isSelected}>
            <div className="dateContainer">
                <p>{date.toDateString()}</p>
            </div>
            <div className="monthlyProfitContainer">
                <p>{billing.monthlyCumulativeProfit}</p>
            </div>
            <div className="feeContainer">
                <p>{billing.monthDailyCalculatedFee}</p>
            </div>
            <div className="iconContainer" onClick={onClick}>
                <Icon size={24} icon={ic_check} />
            </div>
        </HistoryItemWrapper>
    )
}

const BillingHistory = ({api}) => {
    const [historyLoader, setHistoryLoader] = useState(true);
    const [billingToConvert, setBillingToConvert] = useState([]);
    useEffect(() => {
        async function list() {
            try {
                api.data.accountConfiguration.exchange === 'sfox' ? await api.billing.retrieveBillingHistorySFOX() : api.billing.retrieveBillingHistory();
                setHistoryLoader(false);
            } catch (error) {
                setHistoryLoader(false);
            }
        }
        list();
    }, [api.data.accountConfiguration.exchange]);

    const onClickItem = (billingToAdd) => {
        const billingExists = billingToConvert.find(billing => billing.timestamp === billingToAdd.timestamp);
        if(billingExists) {
            const newBillingToConvert = billingToConvert.filter(billing => billing.timestamp !== billingToAdd.timestamp);
            setBillingToConvert(newBillingToConvert);
        } else {
            setBillingToConvert(oldArray => [...oldArray, billingToAdd]);
        }
    }

    const renderItem = (item, index) => {
        const isSelected = !!billingToConvert.find(billing => billing.timestamp === item.timestamp);
        return (
            <div key={index}>
                <BillingHistoryItem 
                    isSelected={isSelected}
                    billing={item}
                    onSelect={onClickItem}
                />
            </div>
        ) 
    }

    const selectAll = () => {
        setBillingToConvert(() => [...api.data.billingHistory]);
    }

    const convertAndDownload = () => {
        exportCSVFile(headers, billingToConvert)
    }

    return (
        <HistoryWrapper>
            <HistoryItemWrapper>
                <div className="dateContainer">
                    Date
                </div>
                <div className="monthlyProfitContainer">
                    Monthly Profit ($)
                </div>
                <div className="feeContainer">
                    Fee ($)
                </div>
                <div className="iconContainer">
                    Click to export
                </div>
            </HistoryItemWrapper>
            <ListGrid
                componentContainerStyle={{width: '100%'}}
                loading={historyLoader}
                data={api.data.billingHistory}
                component={renderItem}
            />
            <HistoryButtonContainer>
                <FormAction button={{
                    type: 'button',
                    fontSize: `${2}`,
                    fontWeight: '600',
                    borderRadius: '4px',
                    pl: '22px',
                    pr: '22px',
                    colors: 'primaryWithBg',
                    height: `${4}`,
                  }} title={'SELECT ALL'} apiToCall={selectAll} />
                <FormAction disabled={billingToConvert.length === 0} title={'DOWNLOAD'} apiToCall={convertAndDownload} />
            </HistoryButtonContainer>
        </HistoryWrapper>
    )
}

export default withApi(BillingHistory);