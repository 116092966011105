import React, { Fragment } from 'react';
import { BillingInfoWrapper, UserTypeSwitchWrapper } from './billingInfo.style';
import Input from 'reusecore/src/elements/Input';
import Switch from 'reusecore/src/elements/Switch';
import Text from 'reusecore/src/elements/Text';
import { ic_person } from 'react-icons-kit/md/ic_person';
import { ic_location_city } from 'react-icons-kit/md/ic_location_city';
import Icon from 'react-icons-kit';
import colors from 'common/src/theme/crypto/colors';
import PropTypes from 'prop-types';
import Select from 'reusecore/src/elements/Select';
import { SUPPORTED_CURRENCIES } from 'common/src/data/Crypto';
import FormAction from '../FormAction';

const UserTypeSwitch = ({ isCompany, onChange }) => {
  return (
    <UserTypeSwitchWrapper>
      <div className="user-type-icon">
        <Icon size={24} icon={ic_person} />
        <Text content="Individual" />
      </div>
      <Switch
        isMaterial={true}
        switchColor={colors.primary}
        isChecked={isCompany}
        onChange={onChange}
      />
      <div className="user-type-icon">
        <Icon size={24} icon={ic_location_city} />
        <Text content="Company" />
      </div>
    </UserTypeSwitchWrapper>
  );
};

const IndividualProperties = ({
  firstName,
  lastName,
  onChange,
  invalidFields,
  removeInvalidField,
}) => {
  const onChangeInvididual = property => value => {
    removeInvalidField(property);
    onChange({ [property]: value });
  };

  return (
    <Fragment>
      <Input
        htmlFor="firstName"
        value={firstName}
        onChange={onChangeInvididual('firstName')}
        className="billing-form-input"
        label="First name"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('firstName')}
      />
      <Input
        htmlFor="lastName"
        value={lastName}
        onChange={onChangeInvididual('lastName')}
        className="billing-form-input"
        label="Last name"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('lastName')}
      />
    </Fragment>
  );
};

const CompanyProperties = ({
  companyName,
  vatId,
  onChange,
  invalidFields,
  removeInvalidField,
}) => {
  const onChangeCompany = property => value => {
    removeInvalidField(property);
    onChange({ [property]: value });
  };
  return (
    <Fragment>
      <Input
        htmlFor="companyName"
        value={companyName}
        onChange={onChangeCompany('companyName')}
        className="billing-form-input"
        label="Company Name"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('companyName')}
      />
      <Input
        htmlFor="vatId"
        value={vatId}
        onChange={onChangeCompany('vatId')}
        className="billing-form-input"
        label="VAT ID"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('vatId')}
      />
    </Fragment>
  );
};

const AddressProperties = ({
  address: { street, city, state, zipCode, country },
  onChange,
  invalidFields,
  removeInvalidField,
}) => {
  const onChangeAddress = property => value => {
    removeInvalidField(`address.${property}`);
    onChange({
      address: { street, city, state, zipCode, country, [property]: value },
    });
  };
  return (
    <Fragment>
      <Input
        htmlForm="street"
        value={street}
        onChange={onChangeAddress('street')}
        className="billing-form-input"
        label="Street"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('address.street')}
      />
      <Input
        htmlFor="city"
        value={city}
        onChange={onChangeAddress('city')}
        className="billing-form-input"
        label="City"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('address.city')}
      />
      <Input
        htmlFor="state"
        value={state}
        onChange={onChangeAddress('state')}
        className="billing-form-input"
        label="State"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('address.state')}
      />
      <Input
        htmlFor="zipCode"
        value={zipCode}
        onChange={onChangeAddress('zipCode')}
        className="billing-form-input"
        label="ZIP"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('address.zipCode')}
      />
      <Input
        htmlFor="country"
        value={country}
        onChange={onChangeAddress('country')}
        className="billing-form-input"
        label="Country"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('address.country')}
      />
    </Fragment>
  );
};

const ContactInformationProperties = ({
  email,
  mobile,
  invoicingCurrency,
  onChange,
  invalidFields,
  removeInvalidField,
}) => {
  const currentlySelectedOption = SUPPORTED_CURRENCIES.find(
    option => option.value === invoicingCurrency
  );

  const onChangeContactInfo = property => value => {
    removeInvalidField(property);
    onChange({ [property]: value });
  };

  const onChangeInvoicingCurrency = property => option => {
    removeInvalidField(property);
    onChange({ [property]: option.value });
  };

  return (
    <Fragment>
      <Input
        htmlFor="email"
        value={email}
        onChange={onChangeContactInfo('email')}
        className="billing-form-input"
        label="Email"
        inputType="email"
        isMaterial={true}
        invalid={invalidFields.has('email')}
      />
      <Input
        htmlFor="mobile"
        value={mobile}
        onChange={onChangeContactInfo('mobile')}
        className="billing-form-input"
        label="Mobile"
        inputType="text"
        isMaterial={true}
        invalid={invalidFields.has('mobile')}
      />
      <Select
        htmlFor="invoicingCurrency"
        options={SUPPORTED_CURRENCIES}
        value={currentlySelectedOption}
        onChange={onChangeInvoicingCurrency('invoicingCurrency')}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: colors.primary,
          },
        })}
        labelText="Invoicing currency"
        invalid={invalidFields.has('invoicingCurrency')}
      />
    </Fragment>
  );
};
export const BillingInfo = ({
  billingInformation,
  setBillingInformation,
  updateBilling,
  invalidFields,
  removeInvalidField,
}) => {
  const onChange = propertyToOverride => {
    setBillingInformation({ ...billingInformation, ...propertyToOverride });
  };

  const switchUserType = isCompany => {
    setBillingInformation({
      ...billingInformation,
      isCompany,
    });
  };

  return (
    <BillingInfoWrapper>
      <UserTypeSwitch
        isCompany={billingInformation.isCompany}
        onChange={switchUserType}
      />
      {billingInformation.isCompany ? (
        <CompanyProperties
          companyName={billingInformation.companyName}
          vatId={billingInformation.vatId}
          onChange={onChange}
          invalidFields={invalidFields}
          removeInvalidField={removeInvalidField}
        />
      ) : (
        <IndividualProperties
          firstName={billingInformation.firstName}
          lastName={billingInformation.lastName}
          onChange={onChange}
          invalidFields={invalidFields}
          removeInvalidField={removeInvalidField}
        />
      )}

      <AddressProperties
        address={billingInformation.address}
        onChange={onChange}
        invalidFields={invalidFields}
        removeInvalidField={removeInvalidField}
      />
      <ContactInformationProperties
        email={billingInformation.email}
        mobile={billingInformation.mobile}
        invoicingCurrency={billingInformation.invoicingCurrency}
        onChange={onChange}
        invalidFields={invalidFields}
        removeInvalidField={removeInvalidField}
      />
      <FormAction
        title="UPDATE"
        apiToCall={updateBilling.execute}
        disabled={invalidFields.size > 0}
      />
    </BillingInfoWrapper>
  );
};

BillingInfo.propTypes = {
  billingInformation: PropTypes.object,
};
