import React, { createContext, useState } from 'react';
import { Auth, API } from 'aws-amplify';

export const ApiContext = createContext();
export const API_NAME = 'APIGateway';
const API_URL = process.env.GATSBY_API_URL;

API.configure({
  endpoints: [
    {
      name: API_NAME,
      endpoint: API_URL,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
  ],
});

export const INIT_ACCOUNT_CONFIG = {
  bitstamp: {
    uid: '',
    key: '',
    secret: '',
  },
  sfox: {
    key: ''
  },
  enabled: false,
  suspended: false,
  exchange: 'bitstamp'
};

export const INIT_BILLING_INFORMATION = {
  isCompany: false,
  address: {
    street: '',
    city: '',
    zipCode: '',
    country: '',
    state: '',
  },
  email: '',
  mobile: '',
  invoicingCurrency: 'USD',
  firstName: '',
  lastName: '',
};

export const INIT_PROFITS = {
  monthlyGross: null,
  netPnlUSD: null,
  netPnlBTC: null
};

export default function ApiProvider({ children }) {
  const [accountConfiguration, setAccountConfiguration] = useState(
    INIT_ACCOUNT_CONFIG
  );
  const [
    retrieveAccountConfigurationError,
    setRetrieveAccountConfigurationError,
  ] = useState();
  const [
    updateAccountConfigurationError,
    setUpdateAccountConfigurationError,
  ] = useState();

  const [accountBalance, setAccountBalance] = useState();

  const [billingInformation, setBillingInformation] = useState(
    INIT_BILLING_INFORMATION
  );
  const [
    updateBillingInformationError,
    setUpdateBillingInformationError,
  ] = useState();
  const [invalidBillingFields, setInvalidBillingFields] = useState(new Map());

  const [signal, setSignal] = useState();

  const [profits, setProfits] = useState(INIT_PROFITS);
  const [profitsSFOX, setProfitsSFOX] = useState(INIT_PROFITS);

  const [billingHistory, setBillingHistory] = useState([]);

  function validateAddress() {
    const {
      street,
      city,
      zipCode,
      country,
      state,
    } = billingInformation.address;

    const invalidAddressFields = new Map();

    if (!street || street.length === 0) {
      invalidAddressFields.set('address.street', street);
    }

    if (!city || city.length === 0) {
      invalidAddressFields.set('address.city', city);
    }

    if (!zipCode || zipCode.length === 0) {
      invalidAddressFields.set('address.zipCode', zipCode);
    }

    if (!country || country.length === 0) {
      invalidAddressFields.set('address.country', country);
    }

    if (state && typeof state !== 'string') {
      invalidAddressFields.set('address.state', country);
    }

    return invalidAddressFields;
  }

  function validateIndividualBillingInformation() {
    const { firstName, lastName } = billingInformation;
    const invalidFields = new Map();

    if (!firstName || firstName.length === 0) {
      invalidFields.set('firstName', firstName);
    }

    if (!lastName || lastName.length === 0) {
      invalidFields.set('lastName', lastName);
    }

    return invalidFields;
  }

  function validateCompanyBillingInformation() {
    const { companyName, vatId } = billingInformation;
    const invalidFields = new Map();

    if (!companyName || companyName.length === 0) {
      invalidFields.set('companyName', companyName);
    }

    if (!vatId || vatId.length === 0) {
      invalidFields.set('vatId', vatId);
    }

    return invalidFields;
  }

  function validateContactInformation() {
    const { email, mobile, invoicingCurrency } = billingInformation;
    const contactFields = new Map();

    if (!email || email.length === 0) {
      contactFields.set('email', email);
    }

    if (!mobile || mobile.length === 0) {
      contactFields.set('mobile', mobile);
    }

    if (!invoicingCurrency || invoicingCurrency.length === 0) {
      contactFields.set('invoicingCurrency', invoicingCurrency);
    }

    return contactFields;
  }

  function validateBillingInformation() {
    const { isCompany } = billingInformation;

    const contactFields = validateContactInformation();
    const invalidAddressFields = validateAddress();
    const personalFields = isCompany
      ? validateCompanyBillingInformation()
      : validateIndividualBillingInformation();

    const invalidFields = new Map([
      ...Array.from(contactFields),
      ...Array.from(invalidAddressFields),
      ...Array.from(personalFields),
    ]);

    setInvalidBillingFields(invalidFields);
    return invalidFields;
  }

  function removeInvalidBillingField(property) {
    invalidBillingFields.delete(property);
    setInvalidBillingFields(invalidBillingFields);
  }

  async function updateAccountConfiguration() {
    try {
      const result = await API.post(API_NAME, `/configuration/update`, {
        body: accountConfiguration,
      });
      setAccountConfiguration({ ...accountConfiguration, ...result });
      return result;
    } catch (error) {
      console.log(error);
      setUpdateAccountConfigurationError(error);
    }
  }

  async function retrieveAccountConfiguration() {
    try {
      const result = await API.get(API_NAME, `/configuration/get`);
      const {
        billingInformation: existingBillingInformation,
        ...existingAccountConfiguration
      } = result;
      setAccountConfiguration({
        ...accountConfiguration,
        ...existingAccountConfiguration,
      });
      setBillingInformation({
        ...billingInformation,
        ...existingBillingInformation,
      });
      return result;
    } catch (error) {
      console.log(error);
      setRetrieveAccountConfigurationError(error);
    }
  }

  async function updateBillingInformation() {
    setInvalidBillingFields(new Map());
    try {
      const billingInformationToUpdate = {
        ...billingInformation,
        firstName: billingInformation.isCompany
          ? undefined
          : billingInformation.firstName,
        lastName: billingInformation.isCompany
          ? undefined
          : billingInformation.lastName,
        companyName: billingInformation.isCompany
          ? billingInformation.companyName
          : undefined,
        vatId: billingInformation.isCompany
          ? billingInformation.vatId
          : undefined,
      };
      const result = await API.post(API_NAME, `/account/update-billing`, {
        body: billingInformationToUpdate,
      });
      setBillingInformation({
        ...billingInformation,
        ...result.billingInformation,
      });
    } catch (error) {
      console.log(error);
      validateBillingInformation();
      setUpdateBillingInformationError(error);
    }
  }

  async function ensureTCApproved() {
    try {
      const result = await API.get(API_NAME, '/account/ensure-tc-signed');
      return result;
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }

  async function updateTermsAndConditions() {
    try {
      const result = await API.post(
        API_NAME,
        '/account/update-terms-and-conditions'
      );
      return result;
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }

  async function getBalance() {
    try {
      const result = await API.get(API_NAME, '/account/balance');
      setAccountBalance(result);
      return result;
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }

  async function getBalanceSFOX() {
    try {
      const result = await API.get(API_NAME, '/account/balanceSFOX');
      setAccountBalance(result);
      return result;
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }

  async function retrieveBillingHistory() {
    try {
      const result = await API.get(API_NAME, '/billing/listBilling');
      setBillingHistory(result);
      return result;
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }

  async function retrieveBillingHistorySFOX() {
    try {
      const result = await API.get(API_NAME, '/billing/listBillingSFOX');
      setBillingHistory(result);
      return result;
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }

  async function getLatestSignal() {
    try {
      const result = await API.get(API_NAME, '/signal/get');
      setSignal(result.signal);
      return result.signal;
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }

  async function getLatestSignalSFOX() {
    try {
      const result = await API.get(API_NAME, '/signal/getSFOX');
      setSignal(result.signal);
      return result.signal;
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }

  async function getProfits() {
    try {
      const result = await API.get(API_NAME, '/billing/getDailyBilling');
      if(!result.empty) {
        const {allTimeProfitAndLoss, allTimeProfitAndLossBTC, monthlyCumulativeProfit} = result;
        setProfits({
          monthlyGross: monthlyCumulativeProfit,
          netPnlBTC: allTimeProfitAndLossBTC,
          netPnlUSD: allTimeProfitAndLoss
        });
      }
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }
  async function getProfitsSFOX() {
    try {
      const result = await API.get(API_NAME, '/billing/getDailyBillingSFOX');
      if(!result.empty) {
        const {allTimeProfitAndLoss, allTimeProfitAndLossBTC, monthlyCumulativeProfit} = result;
        setProfitsSFOX({
          monthlyGross: monthlyCumulativeProfit,
          netPnlBTC: allTimeProfitAndLossBTC,
          netPnlUSD: allTimeProfitAndLoss
        });
      }
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  }

  return (
    <ApiContext.Provider
      value={{
        update: {
          execute: updateAccountConfiguration,
          error: updateAccountConfigurationError,
        },
        get: {
          execute: retrieveAccountConfiguration,
          error: retrieveAccountConfigurationError,
        },
        ensureTCApproved: {
          execute: ensureTCApproved,
        },
        updateTermsAndConditions: {
          execute: updateTermsAndConditions,
        },
        getLatestSignal: {
          execute: getLatestSignal,
        },
        getLatestSignalSFOX: {
          execute: getLatestSignalSFOX
        },
        updateBilling: {
          execute: updateBillingInformation,
          error: updateBillingInformationError,
        },
        data: {
          accountConfiguration,
          billingInformation,
          accountBalance,
          signal,
          profits,
          profitsSFOX,
          billingHistory
        },
        set: {
          setAccountConfiguration,
          setBillingInformation,
        },
        account: {
          getBalance,
          getBalanceSFOX
        },
        billing: {
          getProfits,
          getProfitsSFOX,
          retrieveBillingHistory,
          retrieveBillingHistorySFOX
        },
        validation: {
          billing: {
            validateBillingInformation,
            invalidBillingFields,
            removeInvalidBillingField,
          },
        },
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
