import React, { Fragment } from 'react';
import styled from 'styled-components';
import Container from 'common/src/components/UI/Container';
import { Table } from 'common/src/components/Table';
import { themeGet } from 'styled-system';

const BackTestWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  place-items: stretch;

  .column {
    flex: 1;
    margin: 0 5px;
    flex-grow: 1;
  }

  .graphContainer {
    flex-grow: 2;
    grid-area: 1 / 1 / 2 / 5;

    .graph {
      width: 100%;
      border-radius: 20px;
      box-shadow: ${themeGet('defaultBoxShadow')};
    }
  }

  .infoContainer {
    font-weight: bold;
    grid-area: 1 / 5 / 2 / 7;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    place-items: stretch;
  }

  .arbCircleInfoContainer {
    color: #0014ff;
    grid-area: 1 / 1 / 2 / 2;
  }

  .bitcoinInfoContainer {
    color: #ff0000;
    grid-area: 2 / 1 / 3 / 2;
  }

  .infoTableContainer {
    margin: 5px 0;
    padding: 30px 0;
    display: flex;
    align-items: center;
    border-radius: 20px;
    box-shadow: ${themeGet('defaultBoxShadow')};
    background-color: white;
  }

  @media (max-width: 991px) {
    & {
      display: grid;
      grid-template-columns: 100%;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      margin-bottom: 10px;
      .graphContainer {
        grid-area: 1 / 1 / 2 / 2;
      }
      .infoContainer {
        grid-area: 2 / 1 / 3 / 2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 0px;
        .arbCircleInfoContainer {
          grid-area: 1 / 1 / 2 / 2;
        }
        .bitcoinInfoContainer {
          grid-area: 1 / 2 / 2 / 3;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .infoContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;

      .arbCircleInfoContainer {
        grid-area: 1 / 1 / 2 / 2;
      }
      .bitcoinInfoContainer {
        grid-area: 2 / 1 / 3 / 2;
      }
    }
  }
`;

const DiscalamerWrapper = styled.div`
  .disclamerContainer {
    margin: 5px 0;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    box-shadow:${themeGet('defaultBoxShadow')};
    font-size: 12px;
    span {
      font-weight: bold;
    }
  }
`;

export const BackTest = props => {

  function graphUrl(exchange) {
    return exchange === 'sfox' ? 
      'https://arbcircle-assets.s3-eu-west-1.amazonaws.com/backtest/graph-sfox-21-10-2020.png' :  
      'https://arbcircle-assets.s3-eu-west-1.amazonaws.com/backtest/graph-bitstamp-21-10-2020.png'
      
  }

  function returnAndDrawdown(exchange) {
    return exchange === 'sfox' ? {
      arbcircle: {
        return: '57x',
        drawdown: '-34%'
      },
      bitcoin: {
        return: '23x',
        drawdown: '-84%'
      }
    } : {
      arbcircle: {
        return: '46x',
        drawdown: '-37'
      },
      bitcoin: {
        return: '12x',
        drawdown: '-84%'
      }
    }
  }

  const graphImage = graphUrl(props.exchange);
  const exchangeReturnAndDrawdown = returnAndDrawdown(props.exchange);

  return (
    <Fragment>
      <BackTestWrapper>
        <div className="column graphContainer">
          <img
            className="graph"
            src={graphImage}
          />
        </div>
        <div className="column infoContainer">
          <Container className="infoTableContainer arbCircleInfoContainer">
            <Table
              records={[
                { title: 'Arbcircle return on $1*', value: exchangeReturnAndDrawdown.arbcircle.return },
                { title: 'Arbcircle drawdown', value: exchangeReturnAndDrawdown.arbcircle.drawdown },
              ]}
            />
          </Container>
          <Container className=" infoTableContainer bitcoinInfoContainer">
            <Table
              records={[
                { title: 'Bitcoin return on $1*', value: exchangeReturnAndDrawdown.bitcoin.return },
                { title: 'Bitcoin drawdown', value: exchangeReturnAndDrawdown.bitcoin.drawdown },
              ]}
            />
          </Container>
        </div>
      </BackTestWrapper>
      <DiscalamerWrapper>
        <Container className="disclamerContainer">
          Graph and stats are for informational purpose only. *Graph and stats
          include conservative transaction costs, but doesn't include software
          licence fee.<br/>
          <span>Gross Pnl This Month</span>, <span>Net Pnl Since Inception</span> and <span>Net Pnl Since Inception In BTC</span> are updated at midnight (Continental Europe) everyday and reflect yesterday's situation.
        </Container>
      </DiscalamerWrapper>
    </Fragment>
  );
};
